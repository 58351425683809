import type { SVGProps } from 'react'

const Play = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 25"
    {...props}
  >
    <path
      fill="#fff"
      d="M22.5 12.727a1.476 1.476 0 0 1-.712 1.267L8.28 22.257A1.499 1.499 0 0 1 6 20.989V4.465a1.487 1.487 0 0 1 .761-1.296 1.5 1.5 0 0 1 1.519.028l13.508 8.263a1.475 1.475 0 0 1 .712 1.267Z"
    />
  </svg>
)
export default Play
