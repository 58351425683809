import { cn } from '@lemonenergy/lemonpie-3'
import { useEffect } from 'react'
import type { TransitionMapResult } from 'react-transition-state'

import type { ReasonToSaveMoney } from '~/constants/reasonsToSaveMoney'

type ReasonsToSaveItemProps = ReasonToSaveMoney & {
  itemKey: string
  nextItemKey: string
  initialEntered?: boolean
  animationTimeout: number
  isAnimationActive: boolean
} & TransitionMapResult<string>

const ReasonsToSaveItem = ({
  itemKey,
  label,
  icon: Icon,
  initialEntered,
  stateMap,
  toggle,
  nextItemKey,
  setItem,
  deleteItem,
  animationTimeout,
  isAnimationActive,
}: ReasonsToSaveItemProps) => {
  useEffect(() => {
    setItem(itemKey, { initialEntered })
    return () => {
      deleteItem(itemKey)
    }
  }, [setItem, deleteItem, itemKey, initialEntered])

  const { status, isMounted } = stateMap.get(itemKey) ?? {}

  useEffect(() => {
    if (status !== 'entered') return

    if (!isAnimationActive) return

    const timeout = setTimeout(() => {
      toggle(nextItemKey)
    }, animationTimeout / 2)

    return () => clearTimeout(timeout)
  }, [toggle, nextItemKey, animationTimeout, status, isAnimationActive])

  if (!isMounted) return null

  const preEnterStyle =
    status === 'preEnter' ? 'translate-y-full opacity-0' : ''

  const enteredStyle =
    status === 'entered' ? 'translate-y-0 opacity-100 duration-1000' : ''

  const preExitStyle = status === 'preExit' ? 'order-1' : ''

  const exitingStyle =
    status === 'exiting' ? 'opacity-0 duration-500 order-1' : ''

  const classList = cn([
    'inline-flex gap-x-smallest p-smallest rounded-main bg-limeGreen-10 w-min h-24 transform transition-all order-2 self-end items-center',
    preEnterStyle,
    enteredStyle,
    preExitStyle,
    exitingStyle,
  ])

  return (
    <span className={classList}>
      <span className="typography-field-value-strong text-green-100 whitespace-nowrap">
        {label}
      </span>
      <Icon className="size-20 text-green-60" />
    </span>
  )
}

export default ReasonsToSaveItem
