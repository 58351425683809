import { Button } from '@lemonenergy/lemonpie-3'
import { Help } from '@lemonenergy/lemonpie-icons'
import { Link } from '@remix-run/react'

import {
  AccordionItem,
  AccordionContent,
  AccordionHeader,
  AccordionIcon,
  AccordionTrigger,
  AccordionGroup,
} from '~/components/v3/Accordion'
import appRoutes from '~/constants/appRoutes'
import { hashDataStringMD5 } from '~/utils/hashUtils'

type Question = {
  question: string
  answer: string
}

const HomeFaq = ({ questions }: { questions: Question[] | null }) => {
  if (!questions) return null

  return (
    <section>
      <div className="w-full flex flex-col gap-y-large py-largest px-large max-w-[40.375rem] sm:mx-auto">
        <h2 className="typography-field-value-large text-2xl leading-7 tracking-[-0.02rem] font-medium xl:text-[2rem] xl:leading-[2.375rem] xl:tracking-[-0.075rem] text-green-100">
          Perguntas Frequentes
        </h2>
        <AccordionGroup className="divide-y divide-grey-30">
          {questions.map(item => (
            <AccordionItem key={hashDataStringMD5(item.question)}>
              <AccordionHeader>
                <AccordionTrigger className="py-large flex justify-between">
                  <h3 className="typography-body-small fg-main">
                    {item.question}
                  </h3>
                  <AccordionIcon className="fg-softest" />
                </AccordionTrigger>
              </AccordionHeader>
              <AccordionContent>
                <div className="typography-body-small fg-soft pb-large">
                  {item.answer}
                </div>
              </AccordionContent>
            </AccordionItem>
          ))}
        </AccordionGroup>
        <div className="flex justify-center px-large">
          <Button asChild variant="tertiary" inline className="w-full">
            <Link
              to={appRoutes.HELP}
              className="inline-flex items-center gap-smallest"
            >
              <Help size="small" />
              <span>Central de Ajuda</span>
            </Link>
          </Button>
        </div>
      </div>
    </section>
  )
}

export default HomeFaq
