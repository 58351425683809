import {
  ChartLineUp,
  Buildings,
  PiggyBank,
  UsersThree,
  Certificate,
  HandHeart,
  MapTrifold,
} from '~/components/icons'

export type ReasonToSaveMoney = {
  id: string
  label: string
  icon: typeof ChartLineUp
}

const reasons: Readonly<ReasonToSaveMoney[]> = [
  {
    id: 'increase-stock',
    label: 'Aumentar estoque',
    icon: ChartLineUp,
  },
  {
    id: 'improve-infrastructure',
    label: 'Melhorar infraestrutura',
    icon: Buildings,
  },
  {
    id: 'create-reserve',
    label: 'Criar reserva',
    icon: PiggyBank,
  },
  {
    id: 'hire-employees',
    label: 'Contratar funcionários',
    icon: UsersThree,
  },
  {
    id: 'train-staff',
    label: 'Capacitar equipe',
    icon: HandHeart,
  },
  {
    id: 'retain-customers',
    label: 'Fidelizar clientes',
    icon: Certificate,
  },
  {
    id: 'expand-operations',
    label: 'Ampliar operações',
    icon: MapTrifold,
  },
]

export default reasons
