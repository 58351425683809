import type { SVGProps } from 'react'

const HandHeart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="currentColor">
      <path
        d="M1.5 14.25h3v5.25h-3a.75.75 0 0 1-.75-.75V15a.75.75 0 0 1 .75-.75Zm16.55-10.5A3.667 3.667 0 0 0 14.626 6 3.668 3.668 0 0 0 11.2 3.75c-2.043 0-3.7 1.717-3.7 3.75 0 1.365.656 2.598 1.568 3.75h4.057a1.875 1.875 0 0 1 0 3.75h.375l3.542-.814c2.066-1.523 4.708-3.946 4.708-6.686 0-2.033-1.657-3.75-3.7-3.75Z"
        opacity=".2"
      />
      <path d="M21.593 13.225a2.279 2.279 0 0 0-1.744-.448C21.609 11 22.5 9.233 22.5 7.5c0-2.481-1.996-4.5-4.45-4.5a4.46 4.46 0 0 0-3.425 1.57A4.461 4.461 0 0 0 11.199 3C8.746 3 6.75 5.02 6.75 7.5c0 1.031.304 2.034.943 3.094a2.989 2.989 0 0 0-1.383.787l-2.12 2.12H1.5A1.5 1.5 0 0 0 0 15v3.75a1.5 1.5 0 0 0 1.5 1.5h9.75a.744.744 0 0 0 .182-.023l6-1.5a.653.653 0 0 0 .111-.038l3.645-1.55.04-.02a2.306 2.306 0 0 0 .37-3.896h-.005ZM11.2 4.5a2.92 2.92 0 0 1 2.732 1.781.75.75 0 0 0 1.388 0 2.92 2.92 0 0 1 2.732-1.78C19.649 4.5 21 5.873 21 7.5c0 1.828-1.48 3.897-4.28 5.99l-1.04.239a2.625 2.625 0 0 0-2.555-3.23H9.439C8.629 9.41 8.25 8.45 8.25 7.5c0-1.626 1.35-3 2.95-3ZM1.5 15h2.25v3.75H1.5V15Zm19.072.77-3.563 1.517-5.853 1.463H5.25v-4.19l2.122-2.12a1.487 1.487 0 0 1 1.06-.44h4.693a1.125 1.125 0 1 1 0 2.25H10.5a.75.75 0 1 0 0 1.5h3a.782.782 0 0 0 .168-.019l6.281-1.444.03-.008a.806.806 0 0 1 .59 1.49h.003Z" />
    </g>
  </svg>
)

export default HandHeart
