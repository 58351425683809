import { cn } from '@lemonenergy/lemonpie-3'
import {
  InstagramLogo,
  LinkedinLogo,
  WhatsappLogo,
  YoutubeLogo,
} from '@lemonenergy/lemonpie-icons'
import { Link } from '@remix-run/react'
import { type CSSProperties } from 'react'

import appRoutes from '~/constants/appRoutes'
import lemonConstants from '~/constants/lemonConstants'
import makeWhatsappLink from '~/utils/makeWhatsappLink.util'
import { appendSearchParamsString } from '~/utils/url.utils'

const links = [
  { label: 'Início', to: appRoutes.HOME },
  { label: 'Como funciona', to: appRoutes.HOW_IT_WORKS },
  { label: 'Quem somos', to: appRoutes.ABOUT },
  { label: 'Seja um gerador', to: appRoutes.BECOME_A_GENERATOR },
  { label: 'Ajuda', to: appRoutes.HELP },
  { label: 'Política de Privacidade', to: appRoutes.PRIVACY_POLICY },
  {
    label: 'Código de ética e conduta',
    to: lemonConstants.links.conductCode,
  },
  { label: 'Fazer um relato', to: lemonConstants.links.complianceReport },
  { label: 'Termos de uso', to: appRoutes.TERMS_OF_USE },
  { label: 'Carreiras', to: lemonConstants.links.careers },
  { label: 'Editais', to: appRoutes.NOTICES },
]

const socialLinks = [
  {
    icon: InstagramLogo,
    label: 'Instagram',
    to: lemonConstants.social.instagram,
  },
  {
    icon: LinkedinLogo,
    label: 'LinkedIn',
    to: lemonConstants.social.linkeding,
  },
  {
    icon: YoutubeLogo,
    label: 'YouTube',
    to: lemonConstants.social.youtube,
  },
  {
    icon: WhatsappLogo,
    label: 'Whatsapp',
    to: makeWhatsappLink(
      'Olá, estou vendo o site da Lemon, cheguei no rodapé e tenho dúvidas. Pode me ajudar?',
    ),
  },
]

const currentYear = new Date().getFullYear()

const Footer = () => (
  <footer className="bg-green-100 xl:px-[6rem] text-limeGreen-5">
    <div className="container py-largest px-large xl:py-[6rem] xl:px-[6.5rem]">
      <div className="flex flex-col gap-y-14 sm:gap-y-[3rem]">
        <div className="flex flex-col gap-y-larger sm:grid sm:grid-cols-2">
          <h2 className="flex flex-col text-2xl leading-7 tracking-[-0.02rem] font-medium max-xl:max-w-[180px] xl:text-[2rem] xl:leading-[2.375rem] xl:tracking-[-0.075rem]">
            <span>Junte-se&nbsp;a&nbsp;uma empresa</span>
            <span className="inline-flex w-min text-limeGreen-60 relative">
              reconhecida{' '}
              <img
                src="https://images.energialemon.com.br/site/rodape/icone-reconhecimento.png?format=auto&width=37"
                srcSet="https://images.energialemon.com.br/site/rodape/icone-reconhecimento.png?format=auto&width=37 1x, https://images.energialemon.com.br/site/rodape/icone-reconhecimento.png?format=auto&width=74 2x"
                alt=""
                className="aspect-[37/38] absolute right-[-2.625rem] bottom-2"
                loading="lazy"
                aria-hidden="true"
              />
            </span>
            <span>pelo&nbsp;mercado</span>
          </h2>
          <div className="flex gap-x-largest sm:row-span-2 sm:grid sm:gap-y-largest sm:justify-end">
            <div className="flex flex-col gap-y-smaller">
              <h3 className="typography-caption text-limeGreen-10">
                Reconhecida
              </h3>
              <div className="inline-flex">
                <img
                  src="https://images.energialemon.com.br/site/rodape/logo-sistema-b-white.png?format=auto&height=58"
                  srcSet="https://images.energialemon.com.br/site/rodape/logo-sistema-b-white.png?format=auto&height=58 1x, https://images.energialemon.com.br/site/rodape/logo-sistema-b-white.png?format=auto&height=116 2x"
                  alt="Sistema B"
                  className="aspect-[62/58]"
                  loading="lazy"
                />
                <img
                  src="https://images.energialemon.com.br/site/rodape/logo-100-startups-to-watch-white.png?format=auto&height=58"
                  srcSet="https://images.energialemon.com.br/site/rodape/logo-100-startups-to-watch-white.png?format=auto&height=58 1x, https://images.energialemon.com.br/site/rodape/logo-100-startups-to-watch-white.png?format=auto&height=116 2x"
                  alt="100 Startups to Watch"
                  className="aspect-[62/58]"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="flex flex-col gap-y-smaller">
              <h3 className="typography-caption text-limeGreen-10">
                Autorizada
              </h3>
              <div className="inline-flex">
                <img
                  src="https://images.energialemon.com.br/site/rodape/logo-aneel-white.png?format=auto&height=58"
                  srcSet="https://images.energialemon.com.br/site/rodape/logo-aneel-white.png?format=auto&height=58 1x, https://images.energialemon.com.br/site/rodape/logo-aneel-white.png?format=auto&height=116 2x"
                  alt="Aneel"
                  className="aspect-[62/58]"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="inline-flex max-sm:w-full sm:w-min">
            <Link
              className="w-[calc(100%+0.5rem)] -mx-smallest py-main px-large transition-all bg-limeGreen-60 text-green-100 hover:bg-limeGreen-40 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-4 focus-visible:outline-[#43CA56] rounded-small typography-action active:bg-limeGreen-70 text-center"
              to={appendSearchParamsString(
                appRoutes.ACQUISITION_FLOW_REGISTER_PERSON_DATA,
                'utm_source=marketing_midia_on&utm_medium=site&utm_campaign=cta_footer',
              )}
            >
              Quero&nbsp;ser&nbsp;Lemon
            </Link>
          </div>
        </div>
        <div className="flex flex-col gap-y-larger pt-larger border-t-main border-t-white-24 lg:grid lg:grid-cols-2">
          <div className="flex flex-col gap-y-small">
            <Link to={appRoutes.HOME}>
              <img
                src="https://images.energialemon.com.br/site/rodape/logo-lemon-horizontal-white.png?format=auto&height=28"
                srcSet="https://images.energialemon.com.br/site/rodape/logo-lemon-horizontal-white.png?format=auto&height=28 1x, https://images.energialemon.com.br/site/rodape/logo-lemon-horizontal-white.png?format=auto&height=56 2x"
                alt="Lemon"
                className="aspect-[115/28] block"
                width={115}
                height={28}
                loading="lazy"
              />
            </Link>
            <span className="text-limeGreen-60 typography-action-small">
              Energia solar repensada
            </span>
          </div>
          <ul className="flex flex-col gap-y-main gap-x-largest lg:row-start-1 lg:row-end-4 lg:col-start-2 lg:grid lg:grid-cols-[repeat(2,_min-content)] lg:justify-self-end">
            {links.map((link, index) => {
              const half = Math.floor(links.length / 2)
              const isSecondColumn = index >= half
              const rowIndex = isSecondColumn ? index - half + 1 : index + 1
              return (
                <li
                  key={link.label}
                  style={
                    {
                      '--row-start': rowIndex,
                    } as CSSProperties
                  }
                  className={cn(
                    'lg:col-span-1 lg:row-start-[var(--row-start)]',
                    isSecondColumn ? 'lg:col-start-2' : 'lg:col-start-1',
                  )}
                >
                  <Link
                    to={link.to}
                    target="_blank"
                    className="inline-flex transition-all focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-4 focus-visible:outline-[#43CA56] typography-action-small relative before:absolute before:z-[1] before:-top-2 before:-left-4 before:-right-4 before:-bottom-2 before:rounded-smallest before:trasition-all hover:text-limeGreen-10 active:before:bg-green-90 rounded-smallest text-limeGreen-5"
                  >
                    <span className="z-[2] lg:whitespace-nowrap">
                      {link.label}
                    </span>
                  </Link>
                </li>
              )
            })}
          </ul>
          <div className="flex flex-col gap-y-main sm:col-start-1">
            <div>
              <Link
                className="w-fit typography-action-small inline-flex transition-all focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-4 focus-visible:outline-[#43CA56] relative before:absolute before:z-[1] before:-top-2 before:-left-4 before:-right-4 before:-bottom-2 before:rounded-smallest before:trasition-all hover:text-limeGreen-10 active:before:bg-green-90 rounded-smallest text-limeGreen-5"
                to="mailto:contato@energialemon.com.br"
                target="_blank"
              >
                <span className="z-[2]">contato@energialemon.com.br</span>
              </Link>
            </div>

            <ul className="flex flex-row gap-main flex-wrap">
              {socialLinks.map(({ label, to, icon: Icon }) => (
                <li key={label}>
                  <Link to={to}>
                    <Icon className="text-limeGreen-10 size-larger" />
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="sm:col-start-1">
            <span className="typography-action-small">
              {currentYear} © Lemon Energia
            </span>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
