const testimonials = [
  {
    id: 'elisa-cafe',
    customer: 'Elisa',
    place: 'Elisa Café',
    testimonial:
      'Sustentável sem investimentos: dar o primeiro passo para um  impacto real foi o que motivou a escolha pela Lemon',
    videoUrl: 'https://www.youtube.com/watch?v=G0bNjUcdnsU',
    videoTitle: 'Conheça a Elisa do Elisa Café',
    cardImage:
      'https://images.energialemon.com.br/site/testimonials/elisa-cafe.png',
  },
  {
    id: 'pizzarone',
    customer: 'Pedro',
    place: 'Pizzarone',
    testimonial:
      'Com a economia da Lemon ele consegue investir em outros setores e afirma "quem ganha é nosso cliente"',
    videoUrl: 'https://www.youtube.com/watch?v=-AXHFNHdK1U',
    videoTitle: 'Conheça o Pedro do Pizzarone',
    cardImage:
      'https://images.energialemon.com.br/site/testimonials/pizzarone.png',
  },
  {
    id: 'bar-do-de',
    customer: 'Ildefonso',
    place: 'Bar do Dé',
    testimonial:
      'Sem custo para aderir e com economia todo mês na conta de energia',
    videoUrl: 'https://www.youtube.com/watch?v=FzEHiQpoKUg',
    videoTitle: 'Conheça o Ildefonso do Bar do Dé',
    cardImage:
      'https://images.energialemon.com.br/site/testimonials/bar-do-de.png',
  },
  {
    id: 'feijoah',
    customer: 'Deborah',
    place: 'Feijoah',
    testimonial:
      'Reduzindo um dos maiores custos do restaurante e contribuindo com o planeta',
    videoUrl: 'https://www.youtube.com/watch?v=6dWit8ftA7s',
    videoTitle: 'Conheça a Deborah do Feijoah Cozinha',
    cardImage:
      'https://images.energialemon.com.br/site/testimonials/feijoah.png',
  },
]

export default testimonials
