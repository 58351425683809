import { useState, useEffect, useRef } from 'react'

/**
 * This hook is useful in cases where you need to wait for the ref of an element to be ready with the node.
 * I.e: Passing the ref of an element as prop after first render to avoid nullish ref.
 *
 * @description a hook to get a ref to an element and a boolean indicating if the element is ready
 */
const useElementRef = <T extends HTMLElement>() => {
  const ref = useRef<T | null>(null)
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    if (ref.current) {
      setIsReady(true)
    }
  }, [])

  return { ref, isReady }
}

export default useElementRef
