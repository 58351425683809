import {
  AndroidLogo,
  AppleLogo,
  Rating,
  StarHalf,
} from '@lemonenergy/lemonpie-icons'
import { Link } from '@remix-run/react'

import AsideAppDetails from './AsideAppDetails'
import ExpandableAppDetails from './ExpandableAppDetails'

import useElementRef from '~/hooks/useElementRef'

const logoMarkStyles =
  'typography-action-small fg-expressive-main inline-flex items-center gap-smallest'

const AndroidMark = () => (
  <Link
    className={logoMarkStyles}
    target="_blank"
    to="https://play.google.com/store/apps/details?id=br.com.energialemon.app&hl=pt_BR#:~:text=A%20Lemon%20%C3%A9%20um%20aplicativo,mensal%20na%20conta%20de%20luz."
  >
    <AndroidLogo size="small" /> <span>Android</span>
  </Link>
)

const IosMark = () => (
  <Link
    className={logoMarkStyles}
    target="_blank"
    to="https://apps.apple.com/br/app/lemon-clientes-energia-limpa/id6467678717"
  >
    <AppleLogo size="small" /> <span>iOS</span>
  </Link>
)

const LemonAppSection = () => {
  const { isReady, ref } = useElementRef<HTMLDivElement>()

  return (
    <section className="px-large container py-largest lg:pt-96 lg:pb-56 flex flex-col gap-larger lg:gap-main lg:flex-row lg:justify-stretch lg:items-center">
      <div className="flex flex-col gap-larger w-full">
        <div className="flex flex-col gap-main">
          <div className="flex flex-col gap-smallest">
            <p className="fg-expressive-main typography-section">Comodidade</p>

            <h1 className="typography-display-small">
              Pague e entenda o seu consumo de energia solar pelo Aplicativo
              Lemon
            </h1>
          </div>

          <div className="flex gap-smaller items-center">
            <div className="flex gap-[2px] items-center fg-expressive-main">
              <Rating size="small" fill />
              <Rating size="small" fill />
              <Rating size="small" fill />
              <Rating size="small" fill />
              <StarHalf size="small" fill />
            </div>

            <div className="typography-field-value-small">
              4.7 (+100 avaliações)
            </div>
          </div>

          <div className="typography-field-value-small inline-flex items-center gap-smallest">
            <span>Disponível para</span> <AndroidMark /> <span>e</span>{' '}
            <IosMark />
          </div>
        </div>

        <ExpandableAppDetails className="lg:hidden" key="app-details-mobile" />

        {isReady && (
          <AsideAppDetails
            key="app-details-desktop"
            className="w-full hidden lg:flex"
            portalNodeRef={ref}
          />
        )}
      </div>

      <div className="w-full hidden lg:block" ref={ref} />
    </section>
  )
}

export default LemonAppSection
