import { Button, cn } from '@lemonenergy/lemonpie-3'
import { Whatsapp } from '@lemonenergy/lemonpie-icons'
import { Link } from '@remix-run/react'

import EconomyCalculatorWidget from './EconomyCalculatorWidget'

import appRoutes from '~/constants/appRoutes'
import makeWhatsappLink from '~/utils/makeWhatsappLink.util'
import { appendSearchParamsString } from '~/utils/url.utils'

const sectionLgStyles = 'lg:py-96 lg:px-[256px]'

const LemonEconomyCalculatorSection = () => {
  return (
    <section
      className={cn(
        'px-large container py-largest rounded-[48px] bg-limeGreen-5 gap-large flex flex-col',
        sectionLgStyles,
      )}
    >
      <div className="flex flex-col gap-smallest mb-smaller lg:mb-0">
        <p className="fg-expressive-main typography-section">Economia</p>

        <h1 className="typography-display-small">
          Economize com energia solar para reinvestir no que importa
        </h1>
      </div>

      <div className="rounded-main px-main py-small xl:p-main flex items-center gap-small bg-limeGreen-20 xl:relative">
        <img
          src="https://images.energialemon.com.br/site/landing-page-assets/lemon-economy-symbol.png"
          srcSet="https://images.energialemon.com.br/site/landing-page-assets/lemon-economy-symbol.png?width=52&format=auto 1x,
          https://images.energialemon.com.br/site/landing-page-assets/lemon-economy-symbol.png?width=104&format=auto 2x"
          alt=""
          width={104}
          height={145}
          aria-hidden="true"
          className="aspect-[104/145] w-[3.25rem] xl:absolute xl:bottom-0 xl:left-[48px] xl:-translate-x-1/2 xl:object-[0px_12px]"
        />

        <p className="typography-body-small fg-expressive-main xl:ml-[80px]">
          <span className="font-semibold">R$ 80 mil economizados:</span>{' '}
          <br className="lg:hidden" />O marco de um cliente que está com a Lemon
          desde 2021
        </p>
      </div>

      <EconomyCalculatorWidget />

      <div className="flex flex-col gap-main lg:gap-large lg:flex-row lg:justify-center">
        <Button
          variant="tertiary"
          className="bdr-expressive-strong border-main bg-transparent lg:w-fit"
          asChild
        >
          <Link
            to={makeWhatsappLink(
              'Olá, vim do site e gostaria de falar com um especialista sobre a Lemon.',
            )}
            className="inline-flex gap-smallest items-center"
            target="_blank"
          >
            <Whatsapp size="main" /> <span>Falar com especialista</span>
          </Link>
        </Button>

        <Button type="button" variant="primary" className="lg:w-fit" asChild>
          <Link
            to={appendSearchParamsString(
              appRoutes.ACQUISITION_FLOW_REGISTER_PERSON_DATA,
              'utm_source=marketing_midia_on&utm_medium=site&utm_campaign=cta_calculadora',
            )}
          >
            Quero ser Lemon
          </Link>
        </Button>
      </div>
    </section>
  )
}

export default LemonEconomyCalculatorSection
