type FormatCurrencyOptions = {
  locales?: string
  currency?: string
}

export const numberFormatter = (
  locales: string = 'pt-BR',
  options?: Parameters<typeof Intl.NumberFormat>[1],
) => new Intl.NumberFormat(locales, options)

export const formatCurrency = (
  value: number,
  { currency, locales }: FormatCurrencyOptions = { currency: 'BRL' },
) => {
  const formatter = numberFormatter(locales, { style: 'currency', currency })
  return formatter.format(value)
}

export const valueFromCurrency = (value: string, fallback = 0) => {
  const number = Number.parseInt(value.replace(/\D*/g, ''), 10)
  return Number.isNaN(number) ? fallback : number
}
