import { cn } from '@lemonenergy/lemonpie-3'
import type { InputHTMLAttributes, LabelHTMLAttributes, SVGProps } from 'react'

const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 256 256"
    className="size-24"
    {...props}
  >
    <rect width={256} height={256} fill="none" />
    <line
      x1={40}
      y1={64}
      x2={216}
      y2={64}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={16}
      style={{
        transformBox: 'fill-box',
      }}
      className="origin-center transition-all duration-300 ease-in group-has-[input#menu-state:checked]/header:duration-300 group-has-[input#menu-state:checked]/header:ease-in group-has-[input#menu-state:checked]/header:translate-y-[64px] group-has-[input#menu-state:checked]/header:rotate-45"
    />
    <line
      x1={40}
      y1={128}
      x2={216}
      y2={128}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={16}
      className="transition-all duration-300 ease-in group-has-[input#menu-state:checked]/header:duration-300 group-has-[input#menu-state:checked]/header:ease-in group-has-[input#menu-state:checked]/header:opacity-0"
    />
    <line
      x1={40}
      y1={192}
      x2={216}
      y2={192}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={16}
      style={{
        transformBox: 'fill-box',
      }}
      className="origin-center transition-all duration-300 ease-in group-has-[input#menu-state:checked]/header:duration-300 group-has-[input#menu-state:checked]/header:ease-in group-has-[input#menu-state:checked]/header:translate-y-[-64px] group-has-[input#menu-state:checked]/header:rotate-[-45deg]"
    />
  </svg>
)

const MenuToggler = ({
  className,
  htmlFor,
  onChange,
  ...props
}: Omit<LabelHTMLAttributes<HTMLLabelElement>, 'onChange'> &
  Pick<InputHTMLAttributes<HTMLInputElement>, 'onChange'>) => {
  return (
    <>
      <input
        className="hidden"
        type="checkbox"
        id={htmlFor}
        onChange={onChange}
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */}
      <label
        htmlFor={htmlFor}
        className={cn(
          'group relative h-40 w-32 px-smallest py-smaller',
          className,
        )}
        aria-controls="primary-navigation"
        aria-expanded="false"
        {...props}
      >
        <Icon />
      </label>
    </>
  )
}

export default MenuToggler
