import { cn } from '@lemonenergy/lemonpie-3'
import { useRef, useState, useCallback, useEffect } from 'react'

import { appTabsContent, resetVideosTimeline, VideoComponent } from './shared'

import useEffectOnce from '~/hooks/useEffectOnce'

const ExpandableAppDetails = ({ className }: { className?: string }) => {
  const [activeTab, setActiveTab] = useState<string | null>(null)
  const contentRefs = useRef<Record<string, HTMLDivElement | null>>({})

  const handleTabClick = useCallback(
    (id: string) => {
      // Close currently open tab if clicking the same one
      if (activeTab === id) {
        // Reset the active tab styles
        const containerEl = contentRefs.current[id]?.parentElement

        // Hide content
        if (contentRefs.current[id])
          contentRefs.current[id].style.maxHeight = '0px'

        // Reset videos
        resetVideosTimeline(containerEl || undefined)

        setActiveTab(null)
        return
      }

      // Close any previously open tab
      if (activeTab !== null && contentRefs.current[activeTab])
        contentRefs.current[activeTab].style.maxHeight = '0px'

      // Open new tab
      const containerEl = contentRefs.current[id]?.parentElement

      // Show content
      if (contentRefs.current[id]) {
        const scrollHeight = `${contentRefs.current[id].scrollHeight}px`
        contentRefs.current[id].style.maxHeight = scrollHeight
      }

      // Reset videos
      resetVideosTimeline(containerEl || undefined)

      setActiveTab(id)
    },
    [activeTab],
  )

  // Open the first tab after first render (if width < 1024px)
  useEffectOnce(() => {
    const timeout = setTimeout(() => {
      const screenWidth = window.innerWidth
      if (screenWidth < 1024 && appTabsContent.length > 0)
        handleTabClick(appTabsContent[0].id)
    }, 250)

    return () => clearTimeout(timeout)
  })

  // Update maxHeight on resize for current open tab
  useEffect(() => {
    const handleResize = () => {
      if (activeTab === null) {
        setActiveTab(appTabsContent[0].id)
        return
      }

      if (contentRefs.current[activeTab]) {
        const scrollHeight = contentRefs.current[activeTab].scrollHeight

        if (scrollHeight === 0) {
          handleTabClick(activeTab)
          return
        }

        contentRefs.current[activeTab].style.maxHeight = `${scrollHeight}px`
      }
    }
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [activeTab, handleTabClick])

  return (
    <div className={cn('flex flex-col gap-main', className)}>
      {appTabsContent.map(({ id, title, content, icon, videoKey }) => {
        const isOpen = activeTab === id
        const buttonExpandedClickAreaStyles = 'before:absolute before:inset-0'

        return (
          <div
            key={`${id}-desktop`}
            className={cn(
              'flex flex-col overflow-hidden active:select-none [&]:border-main rounded-small border-grey-10 p-main relative hover:bg-grey-5',
              isOpen && 'bg-grey-10 border-0',
            )}
          >
            <button
              type="button"
              className={cn(
                'group flex w-full text-left',
                buttonExpandedClickAreaStyles,
              )}
              onClick={() => handleTabClick(id)}
              aria-expanded={isOpen}
              aria-controls={`content-${id}`}
            >
              <div
                className={cn(
                  'fg-soft typography-body-small inline-flex items-center gap-smaller',
                  isOpen && 'fg-expressive-main font-semibold',
                )}
              >
                <span>{icon({ fill: isOpen })}</span>
                <span>{title}</span>
              </div>
            </button>

            <div
              id={`content-${id}`}
              ref={el => (contentRefs.current[id] = el)}
              className={cn(
                'flex flex-col gap-small max-h-0 overflow-hidden',
                'transition-[max-height,margin-top] duration-150 ease-in-out',
                isOpen && 'mt-main',
              )}
            >
              <p className="fg-softer typography-body-small">{content}</p>
              <VideoComponent videoKey={videoKey} />
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ExpandableAppDetails
