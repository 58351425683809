import { Button } from '@lemonenergy/lemonpie-3'
import { BrandShine, Whatsapp } from '@lemonenergy/lemonpie-icons'
import { Link } from '@remix-run/react'
import type { CSSProperties, MouseEvent } from 'react'
import { useState, useMemo } from 'react'

import Play from '~/components/icons/Play'
import { Carousel, CarouselSlide } from '~/components/v3/Carousel'
import appRoutes from '~/constants/appRoutes'
import testimonials from '~/constants/testimonials'
import VideoDialog from '~/routes/__root/__components/VideoDialog'
import makeWhatsappLink from '~/utils/makeWhatsappLink.util'
import { appendSearchParamsString } from '~/utils/url.utils'

const joinedCompanies = [
  {
    name: 'Ambev',
    imageSrc:
      'https://images.energialemon.com.br/site/logos/ambev.horizontal.white.png',
    height: 24,
    aspectRatio: 227 / 88,
  },
  {
    name: 'Wellhub',
    imageSrc:
      'https://images.energialemon.com.br/site/logos/wellhub.horizontal.white.png',
    height: 32,
    aspectRatio: 1037 / 233,
  },
  {
    name: 'Estimulo 2020',
    imageSrc:
      'https://images.energialemon.com.br/site/logos/estimulo2020.horizontal.white.png',
    height: 44,
    aspectRatio: 1037 / 233,
  },
]

const LemonJoinUsSection = () => {
  const [isVideoOpened, setIsVideoOpened] = useState<number | null>(null)

  const selectedVideo = useMemo(() => {
    if (isVideoOpened === null) return null

    return testimonials[isVideoOpened]
  }, [isVideoOpened])

  const handleOpenVideo = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()

    const videoUrl = e.currentTarget.getAttribute('href')
    if (!videoUrl) return
    const testimonialIndex = testimonials.findIndex(
      t => t.videoUrl === videoUrl,
    )

    if (testimonialIndex === -1) return

    setIsVideoOpened(testimonialIndex)
  }

  return (
    <section className="bg-green-60 py-largest rounded-large lg:container lg:px-0 flex flex-col gap-y-large lg:gap-y-largest">
      <div className="max-lg:container lg:w-full max-lg:px-large flex flex-col gap-y-main lg:items-center">
        <BrandShine size="large" className="size-32 text-limeGreen-60" />
        <h2 className="typography-display-small lg:text-[2rem] lg:leading-[2.375rem] lg:-tracking-[0.075rem] flex max-lg:flex-col gap-x-main lg:gap-x-smaller text-limeGreen-5">
          <span>Junte-se a</span>
          <span className="text-limeGreen-60">15.000 negócios</span>
          <span>que fazem a diferença</span>
        </h2>
      </div>
      <Carousel
        options={{ align: 'start', dragFree: true, loop: false }}
        slideSpacing="1rem"
        slideSize="12.688rem"
        maxSlides={4}
        hasNavigation
        className="mx-auto"
        navigationClassName="px-large mt-large"
      >
        {testimonials.map(testimonial => {
          const imageSrc = testimonial.cardImage
          const name = [testimonial.customer, testimonial.place].join(' • ')
          return (
            <CarouselSlide
              asChild
              key={testimonial.id}
              className="w-[12.688rem]"
            >
              <Link
                className="block"
                to={testimonial.videoUrl}
                target="_blank"
                onClick={handleOpenVideo}
              >
                <article className="p-main h-[19.5rem] relative flex flex-col items-start">
                  <img
                    className="z-[1] inset-0 w-full h-full object-cover absolute rounded-main"
                    src={appendSearchParamsString(imageSrc, '?format=auto')}
                    srcSet={`${appendSearchParamsString(imageSrc, '?format=auto&width=203')} 1x, ${appendSearchParamsString(imageSrc, '?format=auto&width=406')} 2x, ${appendSearchParamsString(imageSrc, '?format=auto&width=609')} 3x`}
                    alt={name}
                    width={203}
                    height={312}
                  />
                  <button className="size-40 inline-flex items-center justify-center rounded-small bg-inverted-pressed bg-blend-hard-light -mt-smallest -ml-smallest z-[2] relative">
                    <Play className="size-24" />
                  </button>
                  <p className="relative z-[2] typography-action-small text-white-100 mt-auto">
                    {testimonial.testimonial}
                  </p>
                  <h3 className="relative z-[2] bg-white-24 rounded-infinite py-6 px-12 -ml-smallest text-limeGreen-5 typography-body-small mt-smaller">
                    {name}
                  </h3>
                </article>
              </Link>
            </CarouselSlide>
          )
        })}
      </Carousel>
      <div className="mt-smaller flex flex-col gap-y-main md:gap-y-largest container px-large md:px-0">
        <ul className="inline-flex gap-large md:gap-x-largest relative overflow-hidden items-center justify-center flex-row flex-wrap">
          {joinedCompanies.map(company => {
            const getWidth = (height: number) =>
              Math.round(height * company.aspectRatio)
            return (
              <li
                key={company.name}
                style={
                  {
                    '--img-height': `${company.height}px`,
                  } as CSSProperties
                }
                className="min-w-fit"
              >
                <img
                  src={`${company.imageSrc}?height=32&format=auto`}
                  srcSet={`
                  ${company.imageSrc}?height=32&format=auto ${getWidth(32)}w,
                  ${company.imageSrc}?height=64&format=auto ${getWidth(64)}w,
                  ${company.imageSrc}?height=${company.height}&format=auto ${getWidth(company.height)}w,
                  ${company.imageSrc}?height=${company.height * 2}&format=auto ${getWidth(company.height * 2)}w
                `}
                  className="h-[2rem] lg:h-[var(--img-height)] w-auto"
                  alt={company.name}
                />
              </li>
            )
          })}
        </ul>
        <div className="flex max-md:flex-col gap-main md:inline-flex md:justify-center md:items-center">
          <Button
            asChild
            variant="tertiary"
            className="md:w-fit md:order-2 bg-green-100 text-limeGreen-5 hover:bg-green-90 active:bg-green-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#43CA56]"
          >
            <Link
              to={appendSearchParamsString(
                appRoutes.ACQUISITION_FLOW_REGISTER_PERSON_DATA,
                'utm_source=marketing_midia_on&utm_medium=site&utm_campaign=cta_join_us',
              )}
            >
              Quero fazer parte
            </Link>
          </Button>
          <Button
            asChild
            variant="secondary"
            className="md:w-fit md:order-1
      bg-transparent border-main bdr-expressive-inverted [&&]:fg-inverted-main
      hover:bg-inverted-hover
      active:bg-inverted-pressed
      focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#43CA56]"
          >
            <Link
              to={makeWhatsappLink(
                'Olá, vim do site e gostaria de falar com um especialista sobre a Lemon.',
              )}
              target="_blank"
              className="inline-flex gap-smallest items-center"
            >
              <Whatsapp size="main" /> <span>Falar com especialista</span>
            </Link>
          </Button>
        </div>
      </div>
      <VideoDialog
        isOpen={isVideoOpened !== null}
        name="hero-video"
        title={selectedVideo?.videoTitle ?? ''}
        urlOrId={selectedVideo?.videoUrl ?? ''}
        onClose={() => setIsVideoOpened(null)}
      />
    </section>
  )
}

export default LemonJoinUsSection
