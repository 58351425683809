import type { SVGProps } from 'react'

const PiggyBank = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="currentColor">
      <path
        d="M22.5 10.5v3A1.5 1.5 0 0 1 21 15h-.75l-1.697 4.752a.75.75 0 0 1-.707.498h-1.192a.75.75 0 0 1-.707-.498l-.357-1.002H9.16l-.357 1.002a.75.75 0 0 1-.707.498H6.904a.75.75 0 0 1-.707-.498l-1.228-3.44A7.5 7.5 0 0 1 10.5 3.75h3a7.5 7.5 0 0 1 7.157 5.251H21a1.5 1.5 0 0 1 1.5 1.5Z"
        opacity=".2"
      />
      <path d="M18 10.875a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0ZM14.25 6H10.5a.75.75 0 1 0 0 1.5h3.75a.75.75 0 1 0 0-1.5Zm9 4.5v3A2.25 2.25 0 0 1 21 15.75h-.221l-1.52 4.254a1.5 1.5 0 0 1-1.413.996h-1.192a1.5 1.5 0 0 1-1.413-.996l-.18-.504H9.689l-.18.504A1.5 1.5 0 0 1 8.096 21H6.904a1.5 1.5 0 0 1-1.413-.996l-1.178-3.296a8.22 8.22 0 0 1-2.01-4.535A1.5 1.5 0 0 0 1.5 13.5a.75.75 0 1 1-1.5 0 3 3 0 0 1 2.276-2.906A8.261 8.261 0 0 1 10.5 3h9.75a.75.75 0 1 1 0 1.5h-2.005a8.243 8.243 0 0 1 2.945 3.75 2.25 2.25 0 0 1 2.06 2.25Zm-1.5 0a.75.75 0 0 0-.75-.75h-.343a.75.75 0 0 1-.716-.525A6.74 6.74 0 0 0 13.5 4.5h-3a6.75 6.75 0 0 0-4.977 11.31.75.75 0 0 1 .154.254L6.904 19.5h1.192l.358-1.002A.75.75 0 0 1 9.16 18h6.43a.75.75 0 0 1 .706.498l.358 1.002h1.192l1.698-4.752a.75.75 0 0 1 .706-.498H21a.75.75 0 0 0 .75-.75v-3Z" />
    </g>
  </svg>
)

export default PiggyBank
