import type { SVGProps } from 'react'

type LogoProps = SVGProps<SVGSVGElement>

const Logo = (props: LogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 84 20"
    fill="none"
    {...props}
  >
    <path
      d="M15.3274 10.0279C15.9542 9.99803 16.5596 9.85549 17.1211 9.60816C17.6787 9.36477 18.1907 9.01434 18.6357 8.57038V8.56813C19.3852 7.81769 19.8696 6.87118 20.0356 5.84355C20.0497 5.77256 20.0592 5.70101 20.0671 5.62776C20.093 5.42776 20.1048 5.22381 20.1048 5.01986C20.1048 4.81591 20.093 4.61985 20.0694 4.42379C20.0615 4.35055 20.0514 4.2773 20.0378 4.20575C19.8736 3.17417 19.3891 2.22147 18.6357 1.46934C17.888 0.720584 16.945 0.237753 15.9227 0.0692975C15.6515 0.0236624 15.373 0 15.0922 0C14.8115 0 14.5347 0.0236624 14.2618 0.0692975C13.2372 0.237753 12.2942 0.720584 11.5465 1.46934C11.1037 1.91273 10.7537 2.42767 10.5084 2.986C10.2614 3.54827 10.1208 4.15617 10.0909 4.7838V0.0692975H10.0712V0.0535225L0 0.0557765C0 0.6203 0.0455738 1.17074 0.136721 1.70709C0.700486 5.14888 3.01181 7.94445 6.07932 9.24533C6.74605 9.52703 7.47017 9.73154 8.20779 9.86169C8.67084 9.94958 9.14064 10.0059 9.61664 10.0279H0.13222V20H0.138409C1.48087 20 2.7845 19.7369 4.0105 19.218C5.1971 18.7149 6.26105 17.996 7.17422 17.0816C8.08794 16.1667 8.80587 15.0996 9.30775 13.9131C9.76461 12.828 10.0234 11.6831 10.0769 10.5034V20H10.0831C10.3402 20 10.5934 19.9899 10.8466 19.9724C11.9561 19.893 13.0831 19.64 14.0817 19.218C17.1487 17.9171 19.4606 15.121 20.0243 11.6798C20.1155 11.1434 20.1605 10.5924 20.1605 10.0285H15.3274V10.0279ZM10.0893 10.0256V5.25367C10.1466 6.50723 10.6547 7.67515 11.5465 8.56869C12.4366 9.45999 13.6029 9.96873 14.8548 10.0262H10.0893V10.0256Z"
      fill="currentColor"
    />
    <path
      d="M30.9766 14.5356V2.86765H29.0552V16.2432H36.7594V14.535H30.9766V14.5356Z"
      fill="currentColor"
    />
    <path
      d="M68.9115 6.26204C66.0786 6.26204 64.2461 8.19618 64.2461 11.1884V11.536C64.2461 14.5282 66.0786 16.4623 68.9115 16.4623C71.7444 16.4623 73.596 14.5282 73.596 11.536V11.1884C73.596 8.19618 71.7573 6.26204 68.9115 6.26204ZM66.1112 11.1884C66.1112 9.0717 67.1313 7.85927 68.9115 7.85927C70.6917 7.85927 71.7292 9.04127 71.7292 11.1884V11.536C71.7292 13.6831 70.7283 14.8651 68.9115 14.8651C67.0947 14.8651 66.1112 13.6527 66.1112 11.536V11.1884Z"
      fill="currentColor"
    />
    <path
      d="M59.1814 6.26204C57.8485 6.26204 56.7694 6.83558 56.0486 7.92687C55.5192 6.83614 54.5312 6.26204 53.1747 6.26204C51.7242 6.26204 50.8538 6.92685 50.348 7.5725L50.335 6.48176H48.5464V16.2437H50.3744V10.785C50.3744 8.99226 51.2774 7.87729 52.7319 7.87729C54.0299 7.87729 54.6071 8.60971 54.6071 10.2526V16.2437H56.4352V10.7478C56.4352 8.95057 57.3016 7.87729 58.7538 7.87729C60.0782 7.87729 60.6662 8.60971 60.6662 10.2526V16.2437H62.4942V9.94158C62.4942 7.56855 61.3155 6.2626 59.1775 6.2626H59.182L59.1814 6.26204Z"
      fill="currentColor"
    />
    <path
      d="M45.5618 7.46827C44.7887 6.66656 43.7034 6.26204 42.3401 6.26204C39.505 6.26204 37.673 8.19618 37.673 11.1884V11.536C37.673 14.5304 39.514 16.4646 42.3598 16.4646C44.5412 16.4646 46.1132 15.38 46.6798 13.4893L46.7147 13.3653H44.8388L44.8174 13.4262C44.4506 14.3671 43.6016 14.8645 42.3598 14.8645C40.6556 14.8645 39.6333 13.7563 39.5444 11.8199H46.8142L46.821 11.6526C46.8277 11.518 46.8294 11.3856 46.8294 11.2526C46.8294 9.6272 46.3911 8.32294 45.5641 7.46489L45.5618 7.46714V7.46827ZM42.3401 7.85927C43.8227 7.85927 44.7038 8.69366 44.8996 10.2779H39.6006C39.8437 8.69366 40.7878 7.85927 42.3401 7.85927Z"
      fill="currentColor"
    />
    <path
      d="M80.1009 6.26001C78.5986 6.26001 77.7153 6.95299 77.2117 7.61836L77.1836 6.47973H75.4186V16.2417H77.2247V10.7283C77.2247 8.98966 78.2014 7.86851 79.7143 7.86851C81.0821 7.86851 81.746 8.67247 81.746 10.3243V16.2417H83.5347V9.97448C83.5347 7.57948 82.3149 6.26057 80.0981 6.26057H80.1003L80.1009 6.26001Z"
      fill="currentColor"
    />
  </svg>
)

export default Logo
