import { cn, SegmentedControl } from '@lemonenergy/lemonpie-3'
import * as Slider from '@radix-ui/react-slider'
import type { ChangeEvent } from 'react'
import { useMemo, useState, useRef, useEffect } from 'react'
import { useTransitionMap } from 'react-transition-state'

import ReasonsToSaveItem from './ReasonsToSaveItem'

import { reportClient } from '~/analytics/analytics.client'
import reasonsToSaveMoney from '~/constants/reasonsToSaveMoney'
import { useIntersectionObserver } from '~/hooks/useIntersectionObserver'
import { formatCurrency } from '~/utils/formatNumber'

const timeWithLemonOptions = [
  { id: 'one-year', label: '1 ano', value: 1 },
  { id: 'three-years', label: '3 anos', value: 3 },
  { id: 'six-years', label: '6 anos', value: 6 },
]

const MIN = 500
const MAX = 10000
const STEP = 100
const DESC = 0.15

const DEFAULTS = {
  currentBillValue: 1000,
  timeWithLemon: timeWithLemonOptions[1].value,
}

const EconomyCalculatorWidget = () => {
  const [currentBillValue, setCurrentBillValue] = useState(
    DEFAULTS.currentBillValue,
  )
  const [timeWithLemon, setTimeWithLemon] = useState(DEFAULTS.timeWithLemon)
  const [isAnimationActive, setIsAnimationActive] = useState(false)
  const animationTimeout = 1500
  const animationContainerRef = useRef<HTMLDivElement>(null)

  const entry = useIntersectionObserver(animationContainerRef, {
    threshold: 0.3,
    freezeOnceVisible: false,
  })

  const isVisible = !!entry?.isIntersecting

  const transition = useTransitionMap<string>({
    timeout: animationTimeout,
    mountOnEnter: true,
    unmountOnExit: true,
    preEnter: true,
  })

  useEffect(() => {
    if (!isVisible) {
      setIsAnimationActive(false)
      return
    }

    if (isAnimationActive) return

    setIsAnimationActive(true)
    const firstItemId = reasonsToSaveMoney[0].id
    transition.toggle(firstItemId, true)
  }, [isVisible, isAnimationActive, transition])

  const handleValueChange = (value: number[]) => {
    setCurrentBillValue(value[0])
  }

  const onPeriodChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setTimeWithLemon(Number(value))
  }

  // TODO: prefer '@number-flow/react' when move to vite
  const currentBillValueFormatted = useMemo(() => {
    return formatCurrency(currentBillValue)
  }, [currentBillValue])

  const rawEstimatedEconomy = useMemo(() => {
    return currentBillValue * 12 * DESC * timeWithLemon
  }, [currentBillValue, timeWithLemon])

  const estimatedTotalEconomy = useMemo(() => {
    return formatCurrency(rawEstimatedEconomy)
  }, [rawEstimatedEconomy])

  useEffect(() => {
    reportClient({
      eventKey: 'economy calculator - result changed',
      meta: {
        billValue: currentBillValue,
        periodInYears: timeWithLemon,
        estimatedEconomy: rawEstimatedEconomy,
      },
    })
  }, [currentBillValue, timeWithLemon, rawEstimatedEconomy])

  const childStyles =
    '[&>*:not(:last-child)]:border-b-[1px] *:border-[#F2F5F4] *:py-large *:px-main'

  return (
    <div
      className={cn(
        'flex flex-col bg-main rounded-small [&]:border-main border-[#F2F5F4]',
        childStyles,
      )}
    >
      <div className="flex flex-col gap-large">
        <div className="inline-flex justify-between fg-softer">
          <span className="typography-caption">Conta de luz atual</span>
          <span className="typography-field-value-strong">
            {currentBillValueFormatted}
          </span>
        </div>
        <Slider.Root
          className="relative flex items-center select-none touch-none w-full h-20"
          defaultValue={[DEFAULTS.currentBillValue]}
          min={MIN}
          max={MAX}
          step={STEP}
          onValueChange={handleValueChange}
        >
          <Slider.Track className="bg-grey-20 relative grow rounded-full h-6">
            <Slider.Range className="bg-green-60 absolute h-full rounded-full " />
          </Slider.Track>
          <Slider.Thumb
            className="bg-green-60 block size-20 rounded-full focus-visible:outline-[#43CA56]"
            aria-label="Conta de luz atual"
          />
        </Slider.Root>
      </div>

      <div className="flex flex-col gap-large">
        <p className="typography-caption fg-softer">Tempo com a Lemon</p>

        <SegmentedControl.Root className="[&]:bdr-main">
          {timeWithLemonOptions.map(option => (
            <SegmentedControl.Option key={`option-${option.id}`}>
              <SegmentedControl.Radio
                className="peer/custom-text"
                value={option.value}
                checked={option.value === timeWithLemon}
                name="time-with-lemon"
                onChange={onPeriodChange}
              />

              <SegmentedControl.Content className="peer-[:not(:checked)]/custom-text:fg-softest peer-checked/custom-text:fg-expressive-main ">
                <SegmentedControl.Text>{option.label}</SegmentedControl.Text>
              </SegmentedControl.Content>
            </SegmentedControl.Option>
          ))}
        </SegmentedControl.Root>
      </div>

      <div className="flex flex-col gap-20">
        <div className="inline-flex justify-between fg-softer items-center">
          <span className="typography-caption">Economia total estimada</span>
          <span className="typography-field-value-large text-green-100">
            {estimatedTotalEconomy}
          </span>
        </div>
        <div className="inline-flex justify-between fg-softer items-center">
          <span className="typography-caption flex-1">Reinvista</span>
          <span
            ref={animationContainerRef}
            className="h-24 overflow-hidden grid grid-cols-1 *:col-start-1 *:row-start-1 justify-items-end"
            data-testid="reasons-to-save-money"
          >
            {reasonsToSaveMoney.map((reason, index) => (
              <ReasonsToSaveItem
                key={reason.id}
                itemKey={reason.id}
                nextItemKey={
                  reasonsToSaveMoney[(index + 1) % reasonsToSaveMoney.length].id
                }
                initialEntered={false}
                animationTimeout={animationTimeout}
                isAnimationActive={isAnimationActive}
                {...reason}
                {...transition}
              />
            ))}
          </span>
        </div>
      </div>
    </div>
  )
}

export default EconomyCalculatorWidget
