import type { SVGProps } from 'react'

const UsersThree = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="currentColor">
      <path
        d="M15.75 13.5a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM6 5.25a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm12 0a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
        opacity=".2"
      />
      <path d="M22.95 14.1a.751.751 0 0 1-1.05-.15A4.838 4.838 0 0 0 18 12a.75.75 0 0 1 0-1.5 2.25 2.25 0 1 0-2.179-2.813.75.75 0 1 1-1.453-.375 3.75 3.75 0 1 1 6.163 3.705 6.368 6.368 0 0 1 2.572 2.032.75.75 0 0 1-.153 1.05Zm-5.051 5.775a.75.75 0 1 1-1.299.75 5.344 5.344 0 0 0-9.201 0 .749.749 0 0 1-1.405-.47.75.75 0 0 1 .107-.28 6.756 6.756 0 0 1 3.163-2.805 4.5 4.5 0 1 1 5.472 0 6.756 6.756 0 0 1 3.163 2.805ZM12 16.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.25-5.25A.75.75 0 0 0 6 10.5a2.25 2.25 0 1 1 2.179-2.813.75.75 0 1 0 1.453-.375 3.75 3.75 0 1 0-6.163 3.705A6.37 6.37 0 0 0 .9 13.049a.75.75 0 1 0 1.2.9A4.838 4.838 0 0 1 6 12a.75.75 0 0 0 .75-.75Z" />
    </g>
  </svg>
)

export default UsersThree
