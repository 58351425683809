import {
  Dialog,
  DialogBackdrop,
  DialogBody,
  DialogCloseButton,
  DialogContent,
} from '@lemonenergy/lemonpie-3'
import { useEffect } from 'react'

import YoutubeLite, {
  linksToWarmYoutubeConnections as linksToWarmVideo,
} from '~/components/YoutubeLite'
import { captureVisualization } from '~/utils/tracking'

type VideoDialogProps = {
  isOpen: boolean
  name: string
  title: string
  onClose?: () => void
  urlOrId: string
}

const VideoDialog = ({
  isOpen,
  name,
  title,
  onClose,
  urlOrId,
}: VideoDialogProps) => {
  useEffect(() => {
    isOpen && captureVisualization(`Dialog ${name} visualizada`)
  }, [isOpen, name])

  return isOpen ? (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogBackdrop className="justify-center items-center">
        <DialogContent className="max-w-[640px] rounded-none overflow-visible bg-transparent">
          <DialogCloseButton
            onClick={onClose}
            className="absolute top-0 right-0 rounded-main [&&]:m-0 z-10"
            variant="secondary"
          />
          <DialogBody className="[&&]:p-0 rounded-main overflow-hidden">
            <YoutubeLite
              urlOrId={urlOrId}
              title={title}
              className="aspect-video"
            />
          </DialogBody>
        </DialogContent>
      </DialogBackdrop>
    </Dialog>
  ) : null
}

export { linksToWarmVideo }

export default VideoDialog
