import { Link } from '@remix-run/react'
import type { ChangeEvent } from 'react'
import { useEffect, useRef } from 'react'

import Logo from './Logo'
import MenuToggler from './MenuToogler'

import appRoutes from '~/constants/appRoutes'
import useWindowScroll from '~/hooks/useWindowScroll'
import { appendSearchParamsString } from '~/utils/url.utils'

const links = [
  {
    href: appRoutes.HOW_IT_WORKS,
    label: 'Como funciona',
  },
  {
    href: appRoutes.ABOUT,
    label: 'Quem somos',
  },
  {
    href: appRoutes.BECOME_A_GENERATOR,
    label: 'Seja um gerador',
  },
  {
    href: appRoutes.HELP,
    label: 'Ajuda',
  },
]

const Header = () => {
  const lastScrollY = useRef(0)
  const [{ y: scrollY }] = useWindowScroll()

  const onTogglerChange = (event: ChangeEvent<HTMLInputElement>) =>
    toggleScrollBlock(!!event.target.checked)

  const toggleScrollBlock = (checked: boolean) =>
    document.body.classList.toggle('max-lg:overflow-hidden', checked)

  useEffect(() => {
    toggleScrollBlock(false)

    return () => {
      toggleScrollBlock(false)
    }
  }, [])

  useEffect(() => {
    const header = document.getElementById('header')
    const SCROLL_THRESHOLD = 16

    if (!header) return

    const scrollDifference = lastScrollY.current - scrollY
    const isScrollingUp = scrollDifference > SCROLL_THRESHOLD
    const isScrollingDown = scrollDifference < -SCROLL_THRESHOLD
    const headerIsInView = scrollY >= header.offsetHeight

    if (isScrollingUp || isScrollingDown) {
      const isFixed = isScrollingUp && headerIsInView
      header.setAttribute('data-fixed', isFixed.toString())
    }

    lastScrollY.current = scrollY
  }, [scrollY])

  return (
    <header
      id="header"
      className={[
        'w-full border-main bg-limeGreen-5 border-[#CFE8D2] text-green-60 group/header relative transition delay-150',
        'max-lg:has-[input#menu-state:checked]/header:bg-green-100 max-lg:has-[input#menu-state:checked]/header:text-limeGreen-10 max-lg:has-[input#menu-state:checked]/header:border-green-100',
        'data-[fixed=true]:delay-75 data-[fixed=true]:sticky data-[fixed=true]:top-0 data-[fixed=true]:z-20 data-[fixed=true]:bg-green-100 data-[fixed=true]:text-limeGreen-10 data-[fixed=true]:border-green-100',
      ].join(' ')}
      data-fixed={false}
    >
      <div className="container xl:px-96 py-main px-large justify-between items-center grid grid-cols-2 lg:grid-cols-[auto_1fr_auto]">
        <Link
          to={appRoutes.HOME}
          className="py-[0.625rem] inline-flex col-span-1 max-lg:group-has-[input#menu-state:checked]/header:text-limeGreen-5 group-data-[fixed=true]/header:text-limeGreen-5"
        >
          <Logo
            width="84"
            height="20"
            className="transition-colors min-w-[5.25rem]"
          />
        </Link>
        <MenuToggler
          htmlFor="menu-state"
          className="col-span-1 justify-self-end lg:hidden"
          onChange={onTogglerChange}
        />
        <div
          id="menu"
          className={[
            'grid col-span-2 lg:grid-cols-subgrid lg:m-0 lg:p-0',
            'max-lg:h-0 max-lg:opacity-0',
            'max-lg:group-has-[input#menu-state:checked]/header:h-[calc(100dvh-4.5rem)] max-lg:group-has-[input#menu-state:checked]/header:opacity-100',
            'transition-all duration-300 ease-in-out transition-height w-full clear-both will-change-auto',
            'max-lg:overflow-hidden max-lg:absolute max-lg:top-[4.5rem] max-lg:z-20 max-lg:right-0 max-lg:w-[calc(100%+2px)] max-lg:-left-1 max-lg:bg-green-100 delay-150',
          ].join(' ')}
        >
          <div className="max-lg:container col-span-2 grid lg:grid-cols-subgrid max-lg:gap-y-largest max-lg:p-large">
            <ul className="flex list-none typography-field-value-soft lg:typography-field-value-small flex-col justify-start gap-large lg:col-span-1 lg:inline-flex lg:flex-row lg:justify-self-center">
              {links.map(link => (
                <li key={link.href} className="inline-flex items-center">
                  <Link
                    to={link.href}
                    className={[
                      'inline-flex transition-all typography-field-value-soft lg:typography-field-value-small relative rounded-smallest',
                      'inline-flex transition-all typography-action-small relative rounded-smallest',
                      'before:absolute before:z-[1] before:-top-2 before:-left-4 before:-right-4 before:-bottom-2 before:rounded-smallest before:trasition-all',
                      'focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-4 focus-visible:outline-[#43CA56]',
                      'lg:fg-expressive-strong lg:hover:fg-expressive-main lg:active:before:bg-green-80  lg:active:before:bg-opacity-[0.06] lg:active:before:mix-blend-multiply',
                      'max-lg:text-limeGreen-5 max-lg:hover:text-limeGreen-10 max-lg:active:before:bg-green-90',
                      'group-data-[fixed=true]/header:text-limeGreen-5 group-data-[fixed=true]/header:hover:text-limeGreen-10 group-data-[fixed=true]/header:active:before:bg-green-90',
                    ].join(' ')}
                  >
                    <span className="z-[2]">{link.label}</span>
                  </Link>
                </li>
              ))}
            </ul>
            <div className="flex gap-x-large flex-col lg:inline-flex lg:flex-row lg:col-span-1 gap-y-main justify-end">
              <Link
                to={appendSearchParamsString(
                  appRoutes.ACQUISITION_FLOW_REGISTER_PERSON_DATA,
                  'utm_source=marketing_midia_on&utm_medium=site&utm_campaign=cta_header',
                )}
                className={[
                  'justify-center items-center rounded-small focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#43CA56] transition-all whitespace-nowrap min-w-fit',
                  'flex min-h-56 px-large py-main max-lg:w-full  typography-action',
                  'lg:inline-flex lg:min-h-40 lg:w-fit lg:p-small lg:typography-action-small',
                  'bg-limeGreen-5 text-green-100 hover:bg-limeGreen-10 active:bg-limeGreen-20',
                  'lg:fg-inverted-main lg:bg-expressive-strong [&&]:lg:hover:bg-expressive-strong-hover [&&]:lg:active:bg-expressive-strong-pressed lg:order-2',
                  'group-data-[fixed=true]/header:bg-limeGreen-5 group-data-[fixed=true]/header:text-green-100 group-data-[fixed=true]/header:hover:bg-limeGreen-10 group-data-[fixed=true]/header:active:bg-limeGreen-20',
                ].join(' ')}
              >
                Quero ser Lemon
              </Link>
              <Link
                to={appendSearchParamsString(
                  appRoutes.DOWNLOAD_APP,
                  'utm_source=marketing_midia_on&utm_medium=site&utm_campaign=cta_header',
                )}
                className={[
                  'justify-center items-center rounded-small focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#43CA56] transition-all whitespace-nowrap min-w-fit',
                  'flex min-h-56 px-large py-main max-lg:w-full typography-action',
                  'lg:inline-flex lg:min-h-40 lg:w-fit lg:p-small lg:typography-action-small lg:group-data-[fixed=false]/header:mix-blend-multiply max-lg:border-main',
                  'bg-transparent text-limeGreen-10 max-lg:border-limeGreen-10 max-lg:focus:bg-inverted-hover hover:bg-inverted-hover active:bg-inverted-pressed',
                  'lg:hover:bg-soft-hover lg:active:bg-soft-pressed lg:bg-soft lg:text-green-60',
                  'group-data-[fixed=true]/header:bg-transparent group-data-[fixed=true]/header:text-limeGreen-10 group-data-[fixed=true]/header:border-main group-data-[fixed=true]/header:border-limeGreen-10 group-data-[fixed=true]/header:hover:bg-inverted-hover group-data-[fixed=true]/header:active:bg-inverted-pressed',
                ].join(' ')}
              >
                Já sou cliente
              </Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
