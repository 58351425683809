import {
  type CSSProperties,
  type ForwardedRef,
  forwardRef,
  useState,
} from 'react'

import { type YouTubeLiteProps } from './youtubeLite.types'
import {
  getYouTubeId,
  getYoutubePlayerOptions,
  linksToWarmYoutubeConnections,
} from './youtubeLite.utils'
import YouTubeIcon from './YoutubeLiteIcon'

function RenderYouTubeLite(
  {
    urlOrId,
    customThumbnail,
    iframeProps,
    noCookie = true,
    playerParameters,
    playlist,
    poster = 'hqdefault',
    title,
    className,
    ...props
  }: YouTubeLiteProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const [iframe, setIframe] = useState(false)

  const videoId = encodeURIComponent(getYouTubeId(urlOrId))
  const posterUrl =
    typeof customThumbnail === 'string'
      ? customThumbnail
      : `https://i.ytimg.com/vi/${videoId}/${poster}.jpg`
  const youtubeUrl = noCookie
    ? 'https://www.youtube-nocookie.com'
    : 'https://www.youtube.com'
  const iframeSrc = new URL(
    !playlist
      ? `${youtubeUrl}/embed/${videoId}`
      : `${youtubeUrl}/embed/videoseries`,
  )

  const addIframe = () => {
    if (iframe) return
    setIframe(true)
  }

  const overlayStyles =
    'before:box-content before:block before:absolute before:top-0 before:bottom-0 before:transition-all before:duration-200 before:ease-[cubic-bezier(0,0,0.2,1)] before:w-full before:bg-gradient-to-b before:from-black-100/20 before:via-transparent before:to-black-100/20'

  return (
    <div
      className={[
        `group relative bg-black bg-center bg-cover cursor-pointer bg-[image:var(--posterUrl)]`,
        overlayStyles,
        iframe ? 'before:hidden' : '',
        className,
      ]
        .filter(Boolean)
        .join(' ')}
      style={{ '--posterUrl': `url(${posterUrl})` } as CSSProperties}
      data-title={title}
      onClick={addIframe}
      onKeyDown={addIframe}
      role="button"
      tabIndex={0}
      ref={ref}
      {...props}
    >
      {iframe ? (
        <iframe
          className="border-0 h-full w-full"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          data-testid="le-yt-iframe"
          height={315}
          src={getYoutubePlayerOptions({
            url: iframeSrc,
            videoId,
            isPlaylist: playlist,
            ...playerParameters,
          })}
          title={title}
          width={560}
          {...iframeProps}
        />
      ) : (
        <div
          className="appearance-none border-0 box-border h-auto left-1/2 m-0 outline-none p-0 absolute top-1/2 -translate-x-1/2 -translate-y-1/2 w-[68px] z-[1]"
          aria-label="Play"
        >
          <div className="box-border text-[#212121] h-auto w-[68px] opacity-80 transition-all duration-200 ease-[cubic-bezier(0,0,0.2,1)] group-hover:text-[#f00] group-hover:opacity-100">
            <YouTubeIcon />
          </div>
        </div>
      )}
    </div>
  )
}

const YouTubeLite = forwardRef(RenderYouTubeLite)

export { linksToWarmYoutubeConnections }

export default YouTubeLite
