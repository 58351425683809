import {
  NotificationReceived,
  Savings,
  DesktopTower,
  ClockCounterClockwise,
} from '@lemonenergy/lemonpie-icons'

const tapsContentIconStyle = 'fg-softer'

export const appTabsContent = [
  {
    id: 'fatura-digital',
    title: 'Fatura digital simplificada',
    content:
      'Receba a fatura digital, pague por pix ou boleto, acesse a 2a via de forma fácil',
    icon: ({ fill }: { fill?: boolean } = {}) => (
      <DesktopTower size="main" fill={fill} className={tapsContentIconStyle} />
    ),
    videoKey: 'card-fatura',
  },
  {
    id: 'economia-garantida',
    title: 'Economia garantida todo mês',
    content:
      'Economize todo os meses com o consumo de energia solar e pague o residual de consumo convencional e taxas de energia para a distribuidora',
    icon: ({ fill }: { fill?: boolean } = {}) => (
      <Savings size="main" fill={fill} className={tapsContentIconStyle} />
    ),
    videoKey: 'card-economia',
  },
  {
    id: 'historico-consumo',
    title: 'Histórico de consumo',
    content:
      'Acompanhe o consumo e oscilações, obtenha insights de meses que economiza mais',
    icon: ({ fill }: { fill?: boolean } = {}) => (
      <ClockCounterClockwise size="main" fill={fill} />
    ),
    videoKey: 'card-historico',
  },
  {
    id: 'lembrete-pagamento',
    title: 'Lembrete de pagamento',
    content: 'Receba lembretes das suas contas de energia antes do vencimento',
    icon: ({ fill }: { fill?: boolean } = {}) => (
      <NotificationReceived
        size="main"
        fill={fill}
        className={tapsContentIconStyle}
      />
    ),
    videoKey: 'card-lembrete',
  },
]

export const VideoComponent = ({ videoKey }: { videoKey: string }) => (
  <div className="w-full grid grid-cols-1 grid-rows-1">
    <video
      autoPlay
      loop
      muted
      playsInline
      className="w-full pointer-events-none rounded-small"
    >
      <source
        src={`https://assets.lemon.energy/crescimento/${videoKey}.webm`}
        type="video/webm"
      />
      <source
        src={`https://assets.lemon.energy/crescimento/${videoKey}.mp4`}
        type="video/mp4"
      />
    </video>
  </div>
)

export const resetVideosTimeline = (elementToQuery?: HTMLElement | null) => {
  elementToQuery?.querySelectorAll<HTMLVideoElement>('video').forEach(video => {
    if (video.currentTime > 0) video.currentTime = 0
  })
}
