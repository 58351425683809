import { debounce } from 'perfect-debounce'
import { useCallback, useEffect, useState } from 'react'

type ScrollToOptions = {
  top: number
  left: number
  behavior?: ScrollBehavior
}

export default function useWindowScroll() {
  const [state, setState] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  })

  const scrollTo = useCallback((x: number | ScrollToOptions, y?: number) => {
    const hasOptions = typeof x === 'object' && x !== null
    const isTupleNumber = typeof x === 'number' && typeof y === 'number'

    if (isTupleNumber) return window.scrollTo(x, y)
    if (hasOptions) return window.scrollTo(x)

    return window.scrollTo(x, 0)
  }, [])

  useEffect(() => {
    const ab = new AbortController()
    const handleScroll = debounce(
      () => {
        if (state.x === window.scrollX && state.y === window.scrollY) return

        setState({ x: window.scrollX, y: window.scrollY })
      },
      25,
      { leading: false, trailing: true },
    )

    handleScroll()
    window.addEventListener('scroll', handleScroll, ab)

    return () => {
      ab.abort()
    }
  }, [state])

  return [state, scrollTo] as const
}
