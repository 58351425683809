import { cn } from '@lemonenergy/lemonpie-3'
import { OtherPlace, Wind } from '@lemonenergy/lemonpie-icons'
import { Link } from '@remix-run/react'

const hrefMindMiners =
  'https://investalk.bb.com.br/noticias/economia/consumidores-buscam-empresas-sustentaveis-mas-nao-sabem-o-que-e-esg-diz-pesquisa'

const LinkMindMiners = () => (
  <Link to={hrefMindMiners} target="_blank" className="fg-expressive-main">
    Mind Miners
  </Link>
)

const sectionGridStyles =
  'grid grid-cols-1 lg:grid-cols-2 grid-rows-[repeat(3,fit-content(100%))] lg:grid-rows-[repeat(2,fit-content(100%))]'
const sectionGridLgStyles = 'lg:pt-96 lg:pb-56 lg:gap-x-largest'

const bannersContent = [
  {
    icon: <Wind size="main" fill className="fg-main" />,
    title: '93 mil toneladas de CO2 evitados',
    description: 'pela comunidade Lemon desde 2019',
    id: 'co2-avoided',
  },
  {
    icon: <OtherPlace size="main" fill className="fg-main" />,
    title: '571 mil árvores',
    description:
      'retirariam esses gases da atmosfera nos seus primeiros 20 anos',
    id: 'trees-planted',
  },
]

const LemonSustainabilitySection = () => {
  return (
    <section
      className={cn(
        'px-large container py-largest gap-y-small',
        sectionGridStyles,
        sectionGridLgStyles,
      )}
    >
      <div className="flex flex-col gap-smaller pb-main lg:self-end">
        <p className="fg-expressive-main typography-section">
          Sustentabilidade
        </p>

        <h1 className="typography-display-small">
          Transforme a sua energia solar em um diferencial
        </h1>

        <h2 className="typography-body">
          88% dos consumidores dão preferência a negócios que apoiam o meio
          ambiente (Fonte: Pesquisa <LinkMindMiners />)
        </h2>
      </div>

      <div className="lg:col-start-2 lg:row-span-2 rounded-small overflow-hidden lg:self-start">
        <img
          src="https://images.energialemon.com.br/site/landing-page-assets/home-sustainability-picture.png"
          srcSet="https://images.energialemon.com.br/site/landing-page-assets/home-sustainability-picture.png?width=312&format=auto 312w,
          https://images.energialemon.com.br/site/landing-page-assets/home-sustainability-picture.png?width=601&format=auto 601w"
          alt="Ilustração sobre sustentabilidade"
          loading="lazy"
          decoding="async"
          className="aspect-[601/564]"
        />
      </div>

      <div className="flex flex-col gap-main">
        {bannersContent.map(({ icon, title, description, id }) => (
          <div
            key={id}
            className="flex items-center gap-smaller p-small rounded-small bg-limeGreen-5 fg-expressive-main"
          >
            {icon}
            <div className="flex flex-col gap-smallest">
              <p className="font-semibold typography-body-small">{title}</p>
              <p className="typography-field-value-small">{description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default LemonSustainabilitySection
