import { Button, cn } from '@lemonenergy/lemonpie-3'
import { Disco, Plugs, Ai, Whatsapp } from '@lemonenergy/lemonpie-icons'
import { Link } from '@remix-run/react'
import { useEffect, useState } from 'react'

import appRoutes from '~/constants/appRoutes'
import useRafCallback from '~/hooks/useRafCallback'
import makeWhatsappLink from '~/utils/makeWhatsappLink.util'
import { appendSearchParamsString } from '~/utils/url.utils'

const ids = {
  withoutCosts: 'sem-custo-de-placa-propria',
  withoutInstallations: 'sem-reformas-e-instalacoes',
  withoutBureaucracy: 'sem-papelada-e-burocracia',
} as const

const lemonBenefitsContent = [
  {
    id: ids.withoutCosts,
    title: 'Sem custo de placa própria',
    subtitle: 'A Lemon te conecta a usinas solares parceiras à distância',
    banner: {
      content: '+100 usinas solares parceiras',
      icon: () => <Plugs size="main" fill className="text-green-100" />,
    },
  },
  {
    id: ids.withoutInstallations,
    title: 'Sem reformas e instalações',
    subtitle:
      'A energia limpa chega até você pela estrutura existente da sua distribuidora',
    banner: {
      content: 'Clientes de 12 distribuidoras no Brasil',
      icon: () => <Disco size="main" fill className="text-green-100" />,
    },
  },
  {
    id: ids.withoutBureaucracy,
    title: 'Sem papelada e burocracia',
    subtitle:
      'Cadastre-se online, envie sua conta de luz e documento - o resto a Lemon cuida para você',
    banner: {
      content: 'Cadastro em poucos minutos',
      icon: () => <Ai size="main" fill className="text-green-100" />,
    },
  },
] as const

const imageShownStyles = 'opacity-100'
const imageHiddenStyles = 'opacity-0 transition-opacity duration-300 ease-out'
const transitionTimeInMs = 1500
const imgKeys = [
  'usina-illustration-0',
  'usina-illustration-1',
  'usina-illustration-2',
  'usina-illustration-3',
] as const

const DiscoImagesComponent = ({ currentImgKey }: { currentImgKey: number }) =>
  imgKeys.map(imgKey => (
    <img
      key={`img-${imgKey}`}
      src={`https://images.energialemon.com.br/site/landing-page-assets/${imgKey}.png?format=auto&width=360`}
      alt="Imagem ilustrativa de usina"
      className={cn(
        'w-full h-full object-cover row-start-1 col-start-1',
        imgKeys[currentImgKey] === imgKey
          ? imageShownStyles
          : imageHiddenStyles,
      )}
      loading="lazy"
    />
  ))

const discoLogos = [
  'cpfl',
  'light',
  'equatorial',
  'neoenergia',
  'cemig',
  'elektro',
  'neoenergia-pernambuco',
  'energisa',
  'coelba',
] as const

const beforeGradientStyle =
  'before:bg-gradient-to-r before:from-grey-5 before:to-transparent before:absolute before:z-10 before:top-0 before:left-0 before:w-[80px] before:h-full'
const afterGradientStyle =
  'after:bg-gradient-to-l after:from-grey-5 after:to-transparent after:absolute after:z-10 after:top-0 after:right-0 after:w-[80px] after:h-full'
const gradientStyle = `${beforeGradientStyle} ${afterGradientStyle}`

const DiscoLogosComponent = () => (
  <div className={cn('relative flex items-center', gradientStyle)}>
    <div
      className={cn('gap-large px-main items-center slider-track shrink-0')}
      id="discos-sliding-container"
    >
      {discoLogos.map(disco => (
        <div
          key={`img-${disco}`}
          className="h-[78px] flex justify-center items-center shrink-0"
        >
          <img
            src={`https://images.energialemon.com.br/site/discos/logo-${disco}-unsaturated.96h.png?format=auto&height=24`}
            alt="Logo de distribuidora de energia"
          />
        </div>
      ))}

      {/* We duplicate to give the looping effect. */}
      {discoLogos.map(disco => (
        <div
          key={`img-${disco}-shadow`}
          className="h-[78px] flex justify-center items-center shrink-0"
        >
          <img
            src={`https://images.energialemon.com.br/site/discos/logo-${disco}-unsaturated.96h.png?format=auto&height=24`}
            alt="Logo de distribuidora de energia"
          />
        </div>
      ))}
    </div>
  </div>
)

const VideosComponent = () => (
  <div className="w-full h-full flex items-center justify-center bg-[#CFE8D2]">
    <video autoPlay loop muted playsInline className="w-full lg:h-auto">
      <source
        src="`https://assets.lemon.energy/crescimento/card-sem-papelada.webm"
        type="video/webm"
      />
      <source
        src="https://assets.lemon.energy/crescimento/card-sem-papelada.mp4"
        type="video/mp4"
      />
    </video>
  </div>
)

const LemonWhatSection = () => {
  const [currentTimer, setCurrentTimer] = useState(0)
  const [currentImgKey, setCurrentImgKey] = useState(0)

  useRafCallback(dt => setCurrentTimer(timer => timer + dt))

  useEffect(() => {
    if (currentTimer >= transitionTimeInMs) {
      setCurrentTimer(0)
      setCurrentImgKey(currentKey =>
        currentKey === imgKeys.length - 1 ? 0 : currentKey + 1,
      )
    }
  }, [currentTimer])

  const contentMap = {
    [ids.withoutCosts]: <DiscoImagesComponent currentImgKey={currentImgKey} />,
    [ids.withoutInstallations]: <DiscoLogosComponent />,
    [ids.withoutBureaucracy]: <VideosComponent />,
  }

  return (
    <section className="px-large container py-largest lg:py-96">
      <div className="flex flex-col gap-largest">
        <div className="flex flex-col lg:flex-row gap-largest w-full">
          {lemonBenefitsContent.map(({ title, subtitle, banner, id }) => (
            <div
              key={id}
              className="flex flex-col gap-main lg:w-[calc(33.3%-16px)]"
            >
              <div className="flex flex-col gap-smallest">
                <h2 className="typography-title">{title}</h2>
                <h3 className="typography-body fg-softer">{subtitle}</h3>
              </div>

              <div className="flex flex-col gap-smaller mt-auto">
                <div className="h-[144px] w-full overflow-hidden rounded-small grid grid-cols-1 grid-rows-1 bg-grey-5">
                  {contentMap[id]}
                </div>

                <div className="p-small typography-action-small fg-expressive-main rounded-small bg-limeGreen-5 flex items-center gap-smaller">
                  {banner.icon()}
                  <span>{banner.content}</span>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="flex flex-col gap-main lg:gap-large lg:flex-row lg:justify-center">
          <Button
            variant="tertiary"
            className="bdr-expressive-strong border-main bg-transparent lg:w-fit"
            asChild
          >
            <Link
              to={makeWhatsappLink(
                'Olá, vim do site e gostaria de falar com um especialista sobre a Lemon.',
              )}
              className="inline-flex gap-smallest items-center"
              target="_blank"
            >
              <Whatsapp size="main" /> <span>Falar com especialista</span>
            </Link>
          </Button>

          <Button type="button" variant="primary" className="lg:w-fit" asChild>
            <Link
              to={appendSearchParamsString(
                appRoutes.ACQUISITION_FLOW_REGISTER_PERSON_DATA,
                'utm_source=marketing_midia_on&utm_medium=site&utm_campaign=cta_segunda_dobra',
              )}
            >
              Quero ser Lemon
            </Link>
          </Button>
        </div>
      </div>
    </section>
  )
}

export default LemonWhatSection
