import type { YoutubeOptions } from './youtubeLite.types'

export function getYouTubeId(url: string) {
  const arr = url.split(
    /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm,
  )

  return arr[3] ?? arr[0]
}

export function getYoutubePlayerOptions({
  url,
  videoId,
  isPlaylist,
  opts,
}: YoutubeOptions) {
  const options = {
    ...(!isPlaylist
      ? {
          autoplay: 1,
        }
      : {
          list: videoId,
        }),
    ...opts,
  }

  Object.entries(options)
    .filter(([_, value]) => value !== undefined)
    .forEach(([key, value]) => url.searchParams.append(key, `${value}`))

  return url.toString()
}

export const linksToWarmYoutubeConnections = () => [
  {
    rel: 'preconnect',
    href: 'https://www.youtube-nocookie.com',
  },
  {
    rel: 'preconnect',
    href: 'https://www.google.com',
  },
]
