import { cn } from '@lemonenergy/lemonpie-3'
import { Slot } from '@radix-ui/react-slot'
import type { EmblaOptionsType, EmblaCarouselType } from 'embla-carousel'
import Autoplay from 'embla-carousel-autoplay'
import useEmblaCarousel from 'embla-carousel-react'
import { useCallback, type HTMLAttributes, type CSSProperties } from 'react'

import { DotButton, useDotButton } from './__components/DotButtonNavigation'

import type { AttributesSlottable } from 'types/utils'
import { makeCSSVariables } from '~/utils/common.utils'
import { hashDataStringMD5 } from '~/utils/hashUtils'

type CarouselProps = {
  slideSpacing?: string
  slideSize?: string
  options?: EmblaOptionsType
  hasNavigation?: boolean
  navigationClassName?: string
  autoplay?: boolean
  maxSlides?: number
} & HTMLAttributes<HTMLDivElement>

export const Carousel = ({
  options,
  hasNavigation,
  maxSlides,
  slideSpacing = '1rem',
  slideSize = '100%',
  autoplay,
  children,
  className,
  navigationClassName,
}: CarouselProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(
    options,
    autoplay ? [Autoplay()] : [],
  )

  const onNavButtonClick = useCallback((emblaApi: EmblaCarouselType) => {
    const autoplayPlugin = emblaApi?.plugins()?.autoplay
    if (!autoplayPlugin) return

    const resetOrStop =
      autoplayPlugin.options.stopOnInteraction === false
        ? autoplayPlugin.reset
        : autoplayPlugin.stop

    resetOrStop()
  }, [])

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(
    emblaApi,
    onNavButtonClick,
  )

  return (
    <section
      style={makeCSSVariables({
        'slide-spacing': slideSpacing,
        'slide-size': slideSize,
        'max-slides': maxSlides,
      })}
      className={cn(
        'max-w-[min(100%,_calc(calc(calc(var(--slide-size)_+var(--slide-spacing))*var(--max-slides)))+(24rem))]',
        className,
      )}
    >
      <div className="overflow-hidden" ref={emblaRef}>
        <div className="flex touch-pan-y touch-pinch-zoom gap-x-main first:ml-large last:mr-large lg:first:ml-0 lg:last:mr-0">
          {children}
        </div>
      </div>

      {hasNavigation && (
        <div
          className={cn(
            'flex gap-smallest',
            scrollSnaps.length <= 1 ? 'hidden' : '',
            navigationClassName,
          )}
        >
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={hashDataStringMD5(`carousel-dot-${index}`)}
              onClick={() => onDotButtonClick(index)}
              className={cn(
                'h-6 rounded-infinite transition-all duration-300 ease-in-out',
                index === selectedIndex
                  ? 'bg-limeGreen-60 w-main'
                  : 'bg-limeGreen-10 w-6',
              )}
            />
          ))}
        </div>
      )}
    </section>
  )
}

export const CarouselSlide = (
  props: HTMLAttributes<HTMLDivElement> & AttributesSlottable<{}>,
) => {
  const { className, asChild, ...rest } = props
  const Component = asChild ? Slot : 'div'
  return (
    <Component
      style={
        {
          transform: 'translate3d(0, 0, 0)',
        } as CSSProperties
      }
      className={cn('w-[var(--slide-size)] flex-none min-w-0', className)}
      {...rest}
    />
  )
}
