import type { SVGProps } from 'react'

const ChartLineUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="currentColor">
      <path d="M21 5.25V19.5H3v-15h17.25a.75.75 0 0 1 .75.75Z" opacity=".2" />
      <path d="M21.75 19.5a.75.75 0 0 1-.75.75H3a.75.75 0 0 1-.75-.75v-15a.75.75 0 0 1 1.5 0v10.19l4.72-4.72a.75.75 0 0 1 1.06 0L12 12.44l4.94-4.94H15A.75.75 0 1 1 15 6h3.75a.75.75 0 0 1 .75.75v3.75a.75.75 0 1 1-1.5 0V8.56l-5.47 5.47a.747.747 0 0 1-1.06 0L9 11.56l-5.25 5.25v1.94H21a.75.75 0 0 1 .75.75Z" />
    </g>
  </svg>
)

export default ChartLineUp
