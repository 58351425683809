import { cn } from '@lemonenergy/lemonpie-3'
import { useState, useCallback, useEffect } from 'react'
import { createPortal } from 'react-dom'

import { appTabsContent, resetVideosTimeline, VideoComponent } from './shared'

type AsideAppDetailsProps = {
  className?: string
  portalNodeRef: React.RefObject<HTMLDivElement>
}

const AsideAppDetails = ({
  className,
  portalNodeRef,
}: AsideAppDetailsProps) => {
  const [activeTab, setActiveTab] = useState<string | null>(null)

  const handleTabClick = useCallback(
    (id: string) => {
      // No-op if clicking the same tab
      if (activeTab === id) return

      const containerEl = document.querySelector(`[data-tab-id="${id}-mobile"]`)

      // Reset videos
      resetVideosTimeline((containerEl as HTMLElement) || undefined)

      // Open new tab
      setActiveTab(id)
    },
    [activeTab],
  )

  // Open the first tab on initial render
  useEffect(() => {
    if (!activeTab && appTabsContent.length > 0) {
      setTimeout(() => {
        handleTabClick(appTabsContent[0].id)
      }, 100)
    }
  }, [activeTab, handleTabClick])

  return (
    <div className={cn('flex flex-col gap-main', className)}>
      {appTabsContent.map(({ id, title, content, icon, videoKey }) => {
        const isOpen = activeTab === id
        const buttonExpandedClickAreaStyles = 'before:absolute before:inset-0'

        return (
          <div
            key={`${id}-mobile`}
            data-tab-id={`${id}-mobile`}
            className={cn(
              'overflow-hidden active:select-none [&]:border-main rounded-small border-grey-10 p-main relative hover:bg-grey-5',
              isOpen && 'bg-grey-10 border-0',
            )}
          >
            <button
              type="button"
              className={cn(
                'group flex w-full text-left',
                buttonExpandedClickAreaStyles,
              )}
              onClick={() => handleTabClick(id)}
              aria-expanded={isOpen}
              aria-controls={`content-${id}`}
            >
              <div
                className={cn(
                  'fg-soft typography-body-small inline-flex items-center gap-smaller',
                  isOpen && 'fg-expressive-main font-semibold',
                )}
              >
                <span>{icon({ fill: isOpen })}</span>
                <span>{title}</span>
              </div>
            </button>

            {portalNodeRef.current &&
              createPortal(
                <div
                  id={`content-${id}`}
                  data-details-reference={id}
                  className={cn(
                    'flex-col gap-large overflow-hidden hidden rounded-small bg-grey-10 p-large',
                    isOpen && 'flex',
                  )}
                >
                  <VideoComponent videoKey={videoKey} />
                  <p className="fg-softer typography-body-small">{content}</p>
                </div>,
                portalNodeRef.current,
              )}
          </div>
        )
      })}
    </div>
  )
}

export default AsideAppDetails
